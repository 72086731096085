export const departmentInfo = {
  branch: ["CS", "ME", "CE", "EC", "EX", "MBA"],
  semster: [
    "1 SEM",
    "2 SEM",
    "3 SEM",
    "4 SEM",
    "5 SEM",
    "6 SEM",
    "7 SEM",
    "8 SEM",
  ],
};
